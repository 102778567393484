import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DefaultLayout from './layouts/DefaultLayout.tsx';
import Homepage from './pages/Homepage.tsx';
import Notepage from './pages/Notepage.tsx';

const DefaultRoutes = () => {
    return(
        <Routes>
            <Route path='/' element={<DefaultLayout/>} >
                <Route path='/' element={<Homepage/>} />
                <Route path='/note/:title/:file' element={<Notepage/>} />
            </Route>
        </Routes>
    );
}

export default DefaultRoutes;