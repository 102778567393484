import React, { useState, useRef } from 'react';
import SMT from '../images/SMT.png';
import OCR from '../images/OCR.jpg';
import haystack from '../images/haystack.png';
import resume from '../files/resume.pdf';
import pdp from '../images/profilepicture.jpg';
import attention from '../files/attention.pdf';
import rnn1 from '../files/rnn1.pdf';
import russian from '../files/russian.pdf';
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import DateComponent from '../components/DateComponent.tsx';
import StackIcon from 'tech-stack-icons';
import { Timeline } from 'antd';

const Homepage = () => {

    const navigate = useNavigate();

    const projectsRef = useRef(null);
    const thoughtsRef = useRef(null);

    const mlFiles = [
        rnn1,
        attention
    ];

    const cultureFiles = [
        russian
    ];

    const projects = [
        {
            title: 'Haystack',
            description: 'Data Extraction, Summarizing Pitch-Decks',
            image: haystack,
            link: 'https://www.get-haystack.com',
            readMore: (
                <>
                    <a href='https://www.get-haystack.com' target="_blank" className='font-semibold'>
                        Haystack </a> 
                    is a Webapp that extracts and summarizes pitch-decks most relevant data straight from your inbox.
                    Don't let another investment opportunity slip through your fingers.
                </>
            ),
            stack: ['python', 'sql', 'openai', 'react', 'typescript']
        },
        {
            title: 'SaveMyTime',
            description: 'Chrome Extension, Text-summarizer, Keyword-based search',
            image: SMT,
            link: 'https://www.savemytime.fr',
            readMore: (
                <>
                    <a href='https://www.savemytime.fr' target="_blank" className='font-semibold'>
                        SaveMyTime </a> 
                    is a Chrome Extension that summarizes the webpage you are on, based on a keyword-based search. 
                    We all know that sometimes documentation or webpages in general are so long for 
                    nothing and rarely contain accurate and exact answers to our questions.
                </>
            ),
            stack: ['typescript', 'nodejs', 'mongodb', 'openai']
        },
        {
            title: 'OCR Sudoku Solver',
            description: 'Sudoku Solver, Image-based, Neural-Network',
            image: OCR,
            link: 'https://github.com/amaurydelille/SudokuSolver-OCR',
            readMore: (
                <>
                    <a href='https://github.com/amaurydelille/SudokuSolver-OCR' target="_blank" className='font-semibold'>
                        OCR Sudoku Solver </a>
                    is a software that takes a sudoku picture as an input and returns the solved grid, using a neural network.
                </>
            ),
            stack: ['c++']
        }
    ];

    const mlNotes = [
        {
            title: 'RNNs and LSTMs',
            file: '../files/rnn1.pdf',
            date: '16/09/2024'
        },
        {
            title: 'Attention',
            file: '../files/attention.pdf',
            date: '19/09/2024'
        }
    ];

    const cultureNotes = [
        {
            title: 'Russian',
            file: '../files/russian.pdf',
            date: '15/11/2024'
        }
    ];

    const date = new Date();

    const [arrowStates, setArrowStates] = useState(Array(projects.length).fill(false));

    const clickArrow = (index, event) => {
        event.preventDefault();
        let newArrowState = [... arrowStates];
        newArrowState[index] = !arrowStates[index]
        setArrowStates(newArrowState);
    }

    return(
        <div className='flex flex-col mx-5 sm:mx-96 font-DMSans my-3 min-h-screen text-white'>
            <div className='flex flex-col'>
                {/* ABOUT ME REGION */}
                <div>
                    <div className='flex flex-row py-5 gap-3 justify-between items-center my-4'>                      
                        <div className='max-w-md'>
                            <p className='text-3xl font-semibold mb-5 animate-fade-right'>
                                Hi everyone my name is Amaury,<br />
                            </p>
                            <p className='text-lg animate-fade-left'>
                                I'm an undergraduate Computer-Science student based in Paris. I'm passionate about 
                                <span className='font-semibold'> Machine Learning</span> and 
                                <span className='font-semibold'> Software Engineering</span>. I'm currently looking for an 
                                <span className='font-semibold'> internship</span> in these two domains
                                from <span className='font-semibold'> September 2025</span> to 
                                <span className='font-semibold'> January 2026</span>.
                                <br/>
                                <div className='flex mt-5'>
                                    <a href={resume} target='_blank' rel='noopener noreferrer' 
                                        className='text-white text-center flex-wrap p-2 font-medium duration-200 ease-in-out
                                                    rounded-lg bg-gradient-to-r from-fuchsia-500 to-cyan-500
                                                    hover:opacity-70'
                                    >
                                        Check out my resume
                                    </a>
                                </div>
                            </p>
                        </div>
                        <img className='w-52 h-52 rounded-full ml-5 object-cover object-top border-2 border-slate-600' src={pdp}/>
                        </div>
                    </div>

                {/* ROADMAP REGION */}
                <div className='my-3'>
                    <p className='text-2xl font-bold pb-3'>Roadmap</p>
                    <Timeline
                        mode='left'
                        style={{
                            color: 'white'
                        }}
                        className='text-white'
                        items={[
                            {
                                label: 'December 2024',
                                children: 'Ship Haystack'
                            },
                            {
                                label: 'Summer 2025',
                                children: 'Summer Internship in California'
                            },
                            {
                                label: 'September 2025 - January 2026',
                                children: 'Internship in Ho Chi Minh-City'
                            }
                        ]}
                    />
                </div>

                {/* PROJECTS REGIONS */}
                <div className='my-3'>
                    <p ref={projectsRef} className='text-2xl font-bold pb-3'>Projects</p>
                    <div className='flex flex-col justify-between items-center w-full pb-6'>
                    {
                        projects.map((project, index) => (
                            <div key={index} className='flex flex-col border-l border-neutral-200 sm:border-none items-center justify-center w-full my-2'>
                                <div className='flex flex-row items-center justify-between w-full'>
                                    <div className='flex flex-row items-center'>
                                        <a href={project.link} target="_blank" rel="noopener noreferrer">
                                            <img className='hidden w-20 h-20 sm:flex rounded-lg border object-contain border-black bg-black' src={project.image} alt={project.title} />
                                        </a>
                                        <div className='flex flex-col px-2 sm:px-5'>
                                            <a 
                                                className='text-2xl font-medium duration-100 ease-in-out hover:text-neutral-500' 
                                                href={project.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {project.title}
                                            </a>
                                            <p>
                                                {project.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='p-5 cursor-pointer hover:bg-slate-800 rounded-lg'
                                    onClick={(e) => clickArrow(index, e)} onMouseDown={(e) => e.preventDefault()}>
                                        {arrowStates[index] ? (
                                            <DownOutlined onClick={(e) => clickArrow(index, e)} onMouseDown={(e) => e.preventDefault()} />
                                        ) : (
                                            <UpOutlined onClick={(e) => clickArrow(index, e)} onMouseDown={(e) => e.preventDefault()} />
                                        )}
                                    </div>
                                </div>

                                { arrowStates[index] && (
                                    <div className='flex-grow flex-col items-center w-full my-5 rounded-lg p-5 break-words overflow-hidden text-ellipsis'>
                                        <p className='text-lg breaks-word '>
                                            {project.readMore}
                                        </p>    
                                        <div className='flex flex-row pt-3 items-center'>
                                            <p className='font-semibold pr-1'>Stack: </p>
                                            {
                                                project.stack.map((item, index) => (
                                                    <div className='px-2 w-10 sm:px-3 sm:w-20'>
                                                        <StackIcon name={item}/>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                        }
                    </div>
                </div>

                {/* THOUGHTS AND NOTES REGION*/}
                <div ref={thoughtsRef}>
                    <p className='text-2xl font-bold pb-4'>Thoughts & Notes</p>
                    <div>
                        <p className='text-xl font-semibold'>Machine Learning</p>
                            {
                                mlNotes.map((note, index) => (
                                    <div className='flex flex-row items-center justify-between py-2 px-2'>
                                        <a
                                            href={mlFiles[index]} target='_blank' rel='noopener noreferrer'
                                            className='text-lg duration-100 ease-in-out hover:text-neutral-500 cursor-pointer underline'
                                        >
                                            {note.title}
                                        </a>

                                        <DateComponent publicationDate={note.date} />
                                    </div>
                                ))
                            }
                            <p className='text-xl font-semibold'>Culture</p>
                            {
                                cultureNotes.map((note, index) => (
                                    <div className='flex flex-row items-center justify-between py-2 px-2'>
                                        <a
                                            href={cultureFiles[index]} target='_blank' rel='noopener noreferrer'
                                            className='text-lg duration-100 ease-in-out hover:text-neutral-500 cursor-pointer underline'
                                        >
                                            {note.title}
                                        </a>

                                        <DateComponent publicationDate={note.date} />
                                    </div>
                                ))
                            }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homepage;