import React from 'react';

type DateComponentProp = {
    publicationDate: string;
};

const DateComponent = ({ publicationDate }: DateComponentProp) => {
    const date = new Date();
    
    const currentDay = date.getDate();  
    const currentMonth = date.getMonth() + 1; 
    const currentYear = date.getFullYear();

    const [day, month, year] = publicationDate.split('/').map(Number);

    const getAge = () => {
        if (currentYear - year !== 0) {
            return [currentYear - year, currentYear - year === 1 ? 'year' : 'years'];
        } else if (currentMonth - month !== 0) {
            return [currentMonth - month, currentMonth - month === 1 ? 'month' : 'months'];
        } else if (currentDay - day === 0 || currentDay - day === 1) {
            return [1, 'day'];
        } else {
            return [currentDay - day, 'days'];
        }
    };

    const [age, vocab] = getAge();

    return (
        <p className='px-1 text-neutral-500'>{age} {vocab} ago.</p>
    );
};

export default DateComponent;
