import './App.css';
import DefaultRoutes from './routes.tsx';

function App() {
  return (
    <div className="App h-screen overflow-hidden bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-slate-900 to-neutral-950">
      <DefaultRoutes />
    </div>
  );
}

export default App;
