import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FireOutlined } from '@ant-design/icons';

const Header = () => {
    const navigate = useNavigate();

    const openEmailClient = () => {
        window.location.href = 'mailto:amaury.delille@epita.fr?subject=Interest%20in%20Hiring%20Amaury%20Delille&body=Hello%20Amaury,%0D%0A%0D%0AI%20would%20like%20to%20discuss%20opportunities%20with%20you.%0D%0A%0D%0AThanks!';
    };

    const handleHireMeCLick = () => {
        openEmailClient();
    }

    return (
        <div className="sm:sticky sm:top-0 sm:z-10 flex flex-row px-3 sm:px-11 justify-between font-DMSans items-cetnter my-5 backdrop-filter backdrop-blur-lg bg-opacity-30 ">
            <div className="flex flex-col my-4 p-2 items-start">
                <a onClick={() => navigate('/')} className="text-white text-2xl cursor-pointer">Amaury Delille</a>
            </div>

            <button
                className='flex flex-row items-center my-4 gap-1 p-2 text-white border border-neutral-300 rounded-lg
                           duration-300 ease-in-out hover:text-slate-900 hover:bg-white'
                onClick={() => handleHireMeCLick()}
            >
                <FireOutlined className='text-xl'/>
                <p className='text-xl'>Hire Me</p>
            </button>
        </div>
    );
}

export default Header;
