import React from 'react';
import Header from '../components/Header.tsx';
import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
    return(
        <div className=''>
            <Header />
            <Outlet />
        </div>
    );
}

export default DefaultLayout;