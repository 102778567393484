import React from 'react';
import { useParams } from 'react-router-dom';


const Notepage = () => {
    const { title, file } = useParams();
    const pdfPath = require(`../files/${file}.pdf`);
    
    return(
        <div className='flex flex-col font-DMSans mx-2 sm:mx-96'>
            <p className='text-2xl font-bold pb-4'>{title}</p>
            
            <iframe
                width={100}
                src={pdfPath}
                title={title}
                className='w-full h-screen'
                style={{ border: 'none' }}
            ></iframe>
        </div>
    );
}

export default Notepage;